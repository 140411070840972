import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: function () {
      return import('../views/Tienda.vue')
    }
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: function () {
      return import('../views/About.vue')
    }
  },
  {
    path: '/tienda',
    name: 'Tienda',
    component: () => import('../views/Tienda.vue')

  },
  {
    path: '/tienda/productos/:productUrl',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terminos y Conidciones',
    component: () => import('../views/TermsAndConditions.vue')
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Aviso de Privacidad',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/envios-y-devoluciones',
    name: 'Envios y Devoluciones',
    component: () => import('../views/EnviosYDevoluciones.vue')
  },
  {
    path: '/cesta',
    name: 'Cesta',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/order-details/:orderId',
    name: 'Order Details',
    component: () => import('../views/OrderDetails.vue')
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: () => import('../components/ErrorPage.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
