import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;
Vue.use(VueMeta);

// styles
import '@/assets/scss/master.scss';

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
