<template>
    <v-expand-x-transition  hide-on-leave="true" group="true">
            <v-sheet id="cart-mini" v-show="expand"  color="white" elevation="1" >
                <transition name="fade" appear>
                    <v-container v-if="showContent" >
                        <v-row class="cart-mini-header"  align="center">
                            <v-col cols="9" >
                                <h2>Cesta de compra</h2>
                            </v-col>
                            <v-col cols="2"  align="left">
                                
                                <v-btn @click="close()" icon text :ripple="false" plain white x-large>
                                    <v-icon  x-large color="black" alt="close"> mdi-close  </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="cart-mini-body">
                            <v-col cols='12'>
                                <v-card elevation="0" v-for="(product, index) in cart" :key="'item-'+index" class="mb-3">
                                    <v-row>
                                        <v-col cols="auto">
                                            <v-img width="80" aspect-ratio="1" :src="'https://rawkv3.actstudio.xyz/storage/uploads/'+product.image.path"></v-img>
                                        </v-col>
                                        <v-col class="cart-mini-body-info">
                                            <p>{{product.subCategory}} </p>
                                            <p>{{product.name}}</p>
                                            <p>{{product.size}} <span>-</span> ${{product.price}}</p>
                                            
                                            <div>
                                                <v-btn color="black" icon @click="subtractItem(index)"><v-icon>mdi-minus</v-icon></v-btn> 
                                                {{product.quantity}}
                                                <v-btn color="black" icon @click="addItem(index)"><v-icon>mdi-plus</v-icon></v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-icon color="black" @click="removeProductFromCart(index)">mdi-delete</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="cart-mini-footer " align="center">
                            <v-col cols="12" align="center" class="pb-0">
                                <p> Subtotal: ${{numberWithCommas(subTotal)}}</p>
                                <!-- <p> Total: ${{numberWithCommas(total)}}</p> -->
                            </v-col>
                            <v-col cols="12"  align="center" class="pt-0 cart-mini-buttons">
                                <router-link class="button-primary" :to="'/cesta'">
                                    Cesta de compra
                                </router-link>
                                <router-link v-if="cart.length > 0" class="button-primary" :to="'/checkout'">
                                     Proceder a la compra
                                </router-link>
                            </v-col>
                            
                        </v-row>
                    </v-container>
                </transition>
                
            </v-sheet>
            
    </v-expand-x-transition>
</template>
<script>
export default {
    name: 'CartMini',
    data: () =>{
        return {
            showContent: false
        }
    },
    props: {
        expand: false,
        closeCart: {type: Function},
        cart: []
        //   {  ---Cart object contents---
    //     _id: 
    //     quantity: 
    //     inventory: 
    //     size: 
    //     price: 
    //     image: 
    //     name: 
    //     subCategory: 
    // }
        
    },
    methods: {
        close: function(){
            this.$emit('closeCart');
            this.showContent = false;
        },

        removeProductFromCart: function(index){
            this.$root.$emit('removeProductfromCart', index);
        },

        subtractItem: function(index){
            this.$root.$emit('cartMiniSubtract', index);
        },

        addItem: function(index){
            this.$root.$emit('cartMiniAdd', index);
        },

        numberWithCommas: function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

    },
    computed: {
        subTotal: function(){
            let total = 0;
            this.cart.forEach(item => {
                total += item.quantity * item.price;
            });

            return total;
        },
        // total: function(){
        //     //need to replace the 1000
        //     return this.subTotal;
        // }
    },
    watch: {
        expand: function(newVal){
            this.showContent = newVal;
        }
    }

}
</script>

