<template>
    <v-fade-transition leave-absolute>
        <v-skeleton-loader id="page-loader" transition-group="fade-transition">
            <v-img class="loading-image" width="220px" aspect-ratio="1" :src="require('../assets/img/load-rawk-'+gifNumber+'.gif')"></v-img>
            <div id="footer-logo-loader" align="center">
                <v-img width="105px" src="../assets/img/rawk-logo-black.svg"></v-img>
            </div>
        </v-skeleton-loader>
    </v-fade-transition>
</template>
<script>
export default {
    name: 'PageLoader',
    data: () => {
        return{
            gifNumber: null
        }
    },
    beforeMount: function(){
        this.gifNumber = Math.floor(Math.random() * 5) + 1
    }
}
</script>