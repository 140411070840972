<template>
    <v-footer id="footer" color="transparent">
        <v-row>
            <v-col id="figure-container" cols="12" v-if="figure" align="center">
                <img :src="'https://rawkv3.actstudio.xyz/storage/uploads' + figure.figure.path" />
            </v-col>
            <v-col class="social-links pa-1" cols="12" align="center">
                <a class="sub-links" href="https://www.facebook.com/Rawk-111546357699179" target="_blank">
                    FACEBOOK
                </a>
                <span>/</span>
                <a class="sub-links" href="https://www.instagram.com/rawk.mx/" target="_blank">
                    INSTAGRAM
                </a>
            </v-col>
            <v-col class="pa-1" cols="12" align="center">
                <router-link class="main-links" :to="'/tienda'">
                    TIENDA
                </router-link>
                <span>/</span>
                <router-link class="main-links" :to="'/contacto'">
                    CONTACTO
                </router-link>
            </v-col>
            <v-col class="pa-1" cols="12" align="center">
                <router-link :to="'/terminos-y-condiciones'">
                        Terminos y condiciones
                    </router-link>
                    <span>/</span>
                    <router-link :to="'/aviso-de-privacidad'">
                        aviso de privacidad
                    </router-link>
                    <span>/</span> 
                    <router-link :to="'/envios-y-devoluciones'">
                        envios y devoluciones
                    </router-link>
            </v-col>
        </v-row>
        <div id="footer-logo" align="center">
            <v-img width="105px" src="../assets/img/rawk-logo-black.svg"></v-img>
        </div>
    </v-footer>  
</template>
<script>
export default {
    name: 'Footer',
    data: () => {
        return {
            figure: null
        }
    },
    beforeMount: function(){
        fetch('https://rawkv3.actstudio.xyz/api/content/items/Footer', {
            method: 'GET'
        })
        .then(res => res.json())
        .then(res => this.figure = res[0]);
    }
}
</script>