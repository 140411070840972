<template>
	<v-app>
		<PageLoader v-if="!isLoaded" />
		<HeaderMenu v-bind:cart="cart" />
		<v-main >
			<v-snackbar v-model="snackbar" top :timeout="2000" color="black" >
				{{ snackBarText }}

				<template v-slot:action="{ attrs }">
					<v-btn @click="snackbar = false" icon text :ripple="false" plain white small v-bind="attrs">
						<v-icon   color="white"> mdi-close  </v-icon>
					</v-btn>
				</template>
			</v-snackbar>
			<router-view :cart="cart"/>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import Vue from 'vue'
import HeaderMenu from './components/HeaderMenu.vue';
import Footer from './components/Footer.vue';
import PageLoader from './components/PageLoader.vue';
import VueSession from 'vue-session'
Vue.use(VueSession, {persist: true})

export default {
	name: 'App',
	metaInfo: {
		title: 'RAWK'
	},
	data: () => ({
		isLoaded: false,
		snackBarText: 'Agregado a la cesta',
		snackbar: false,
		cart: [
		//   {  ---Cart object contents---
		//     _id: id of size/price
		//     quantity: 
		//     inventory: 
		//     size: 
		//     price: 
		//     image: {}
		//     name: 
		//     subCategory: 
		// }
		]
	}),
	components: {
		HeaderMenu,
		Footer,
		PageLoader
	},
	beforeMount: function(){
		document.documentElement.style.overflow = 'hidden';
		//check if cart is saved in the session 
		if(!this.$session.has('rcart')){
			this.$session.set('rcart', []);
		}
		this.cart = this.$session.get('rcart');

	},
	mounted: function(){
		this.removeLoading();

		this.$root.$on('addProductToCart', product => {
			this.addToCart(product);
		});

		this.$root.$on('removeProductfromCart', productIndex => {
			this.removeFromCart(productIndex);
		});

		this.$root.$on('cartMiniAdd', productIndex => {
			this.cartMiniAdd(productIndex);
		});

		this.$root.$on('cartMiniSubtract', productIndex => {
			this.cartMiniSubtract(productIndex);
		});

		this.$root.$on('clearCart', () => {
			this.$session.set('rcart', []);
			this.cart = this.$session.get('rcart');

		});
		
	},
	methods: {
		removeLoading: function() {
			setTimeout(() => {
				this.isLoaded = true;
				document.documentElement.style.overflow = 'auto';
			}, 2000);
		},

		addToCart: function(product){
			if(this.cart.length === 0){
				this.cart.push(product);
				this.snackBarText = 'Agregado a la Cesta';
			}else{
			
				let isNewItem = true;

				for(let i = 0; i < this.cart.length; i++){
					if(this.cart[i]._id == product._id){
						isNewItem = false;
						if(this.cart[i].inventory >= this.cart[i].quantity + product.quantity){
							this.cart[i].quantity += product.quantity;
							this.snackBarText = 'Agregado a la Cesta';
						}else{
							//too many added - cant add
						}

							//Exit look since already matched
						break;
					}
				}

				if(isNewItem){
					this.cart.push(product);
					
				}
			}
			
			this.$root.$emit('openCart');
			this.snackbar = true;
			this.$session.set('rcart', this.cart);
		},

		removeFromCart: function(productIndex){
			this.cart.splice(productIndex, 1);
			this.$session.set('rcart', this.cart);
			this.snackbar = true;
			this.snackBarText = 'Eliminado de la Cesta';
		},

		cartMiniAdd: function(productIndex){
			let quantity = this.cart[productIndex].quantity;
			let inventory = this.cart[productIndex].inventory;

			if(inventory >= quantity + 1){
				this.cart[productIndex].quantity += 1;
			}

			this.$session.set('rcart', this.cart);
		},

		cartMiniSubtract: function(productIndex){
			let quantity = this.cart[productIndex].quantity;
			
			if(quantity - 1 > 0 ){
				this.cart[productIndex].quantity -= 1;
			}

			this.$session.set('rcart', this.cart);
		}

	},
	watch: {
		$route: function(){
			this.$vuetify.goTo(0,{duration:0});
			this.isLoaded = false;
			document.documentElement.style.overflow = 'hidden';
			setTimeout(() => {
				this.removeLoading();
			}, 500);
		}
	}
};
</script>
