<template>
    <div>
        <v-app-bar id="menu-header" app color="transparent" flat >
            <v-container>
                <v-row>
                    <v-col class="pl-0 nav-contanier">
                        <v-btn @click="openMenu()" icon text :ripple="false" x-large plain >
                            <v-icon x-large > mdi-menu  </v-icon>
                        </v-btn>

                        <v-btn @click="openCart()" class="pl-0 cart-icon" text :ripple="false" x-small plain >
                            <v-img class="mr-1" src="../assets/img/cart-black.svg"></v-img> ({{cart.length}})
                        </v-btn>

                    </v-col>
                </v-row>
            </v-container>
            
        </v-app-bar>
        <v-expand-transition>
            <v-sheet id="menu-content" v-show="expand"  color="black" elevation="1" >
                <v-container>
                <v-row>
                    <v-col cols="12 nav-contanier" class="pl-0">
                        <v-btn @click="expand = !expand" icon text :ripple="false" plain dark x-large>
                            <v-icon  x-large > mdi-close  </v-icon>
                        </v-btn>
                        <v-btn @click="openCart()"  class="pl-0 cart-icon" text :ripple="false" x-small plain color="white">
                            <v-img class="mr-1" src="../assets/img/cart-white.svg"></v-img> ({{cart.length}})
                            
                        </v-btn>
                    </v-col>
                </v-row>  
                <v-row>
                    <v-col cols="12"  md="4">
                        <router-link class="main-links" :to="'/tienda'">
                            TIENDA
                        </router-link>
                        <router-link class="main-links" :to="'/contacto'">
                            CONTACTO
                        </router-link>
                        <br>
                        <a class="sub-links" href="https://www.facebook.com/Rawk-111546357699179" target="_blank">
                            FACEBOOK
                        </a>
                        <a class="sub-links" href="https://www.instagram.com/rawk.mx/" target="_blank">
                            INSTAGRAM
                        </a>
                    </v-col>
                    <v-col cols="12"  md="4" class="code-container">
                        <div v-html="promoText"></div>
                    </v-col>
                </v-row>
              
                
                <div class="menu-bottom-links" >
                    <router-link :to="'/terminos-y-condiciones'">
                        Terminos y condiciones
                    </router-link>
                    <span>/</span>
                    <router-link :to="'/aviso-de-privacidad'">
                        aviso de privacidad
                    </router-link>
                    <span>/</span> 
                    <router-link :to="'/envios-y-devoluciones'">
                        envios y devoluciones
                    </router-link>
                </div>
                </v-container>
                
            </v-sheet>
        </v-expand-transition>
        
        <CartMini v-bind:expand="expandMini" v-bind:cart="cart" @closeCart="closeCart" />
        <v-overlay @click.native="closeCart()"  :value="expandMini" fixed z-index="5"></v-overlay>
    </div>
</template>

<script>
import CartMini from './CartMini.vue';
export default {
    name: 'HeaderMenu',
    data: () => {
        return {
            expand: false,
            promoText: null,
            expandMini: false
  
        }
    },
    props: {
        cart: []
    },
    components: {
        CartMini
    },
    beforeMount: function(){
        fetch('https://rawkv3.actstudio.xyz/api/content/items/Menu', {
            method: 'GET'
        })
        .then(res => res.json())
        .then(res => {
            //if promo text is empty, assign an empty string to not break includes
            let text = res[0].text || '';
            let newPromoText = '';

            if(text.includes('/storage')){
                newPromoText = text.split('/storage').join('https://rawkv3.actstudio.xyz/storage');
                text = newPromoText;
            }

            this.promoText = text;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    },
    mounted: function() {
        this.$root.$on('openCart', () => {
			this.openCart();
		});
    },
    methods: {
        openCart: function() {
            this.expand = false;
            this.expandMini = true;
            document.documentElement.style.overflow = 'hidden';
        },

        closeCart: function(){
            this.expandMini = false;
            document.documentElement.style.overflow = 'auto';
        },

        openMenu: function(){
            this.expand = true;
            this.expandMini = false;
        }

    },
    watch: {
        $route: function(){
            this.expand = false;
            this.expandMini = false;
        }
    }
}
</script>